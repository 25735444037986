import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Verifique nuestros precios</h1>
            <p className="font13">
              Tenemos planes en todos nuestros servicios y productos.
              {/* <br />
              labore et dolore magna aliquyam erat, sed diam voluptua. */}
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="microservices"
                price=" desde $69,99/mo"
                title="Wsigef"
                text="Crea, Administra y aumenta tus créditos."
                offers={[
                  { name: "Servicio de Créditos", cheked: true },
                  { name: "Administrador Financiero", cheked: true },
                  { name: "Gestion de Cobros y Procesos", cheked: true },
                  { name: "Contabilidad", cheked: true },
                  { name: "Tesoreria y Pagos", cheked: true },
                ]}
                action={() => {
                  const contactSection = document.getElementById("contact");
                  if (contactSection) {
                    contactSection.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="desde $19,99/mo"
                title="Inn Pos"
                text="Vende, administra y mejora tu sistema de ventas."
                offers={[
                  { name: "Ventas", cheked: true },
                  { name: "Inventarios", cheked: true },
                  { name: "Facturación Electronica", cheked: true },
                  { name: "Configuracion y Parametros", cheked: true },
                  { name: "Portabilidad", cheked: true },
                ]}
                action={() => {
                  const contactSection = document.getElementById("contact");
                  if (contactSection) {
                    contactSection.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="desde $29,99/mo"
                title="Shortcut"
                text="Mejora la confianza de tu marca y aumenta los clicks."
                offers={[
                  { name: "Recorte Enlace", cheked: true },
                  { name: "Administración", cheked: true },
                  { name: "Configuración", cheked: true },
                  // { name: "Product", cheked: true },
                  // { name: "Product Offer", cheked: true },
                ]}
                action={() => {
                  const contactSection = document.getElementById("contact");
                  if (contactSection) {
                    contactSection.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="desde $59,99/mo"
                title="Signature"
                text="Mejora tu servicio al ofrecer firmas digitales."
                offers={[
                  { name: "Firma Documento", cheked: true },
                  { name: "Administración Documentos", cheked: true },
                  { name: "Configuración", cheked: true },
                  { name: "Personalización", cheked: true },
                  // { name: "Product Offer", cheked: true },
                ]}
                action={() => {
                  const contactSection = document.getElementById("contact");
                  if (contactSection) {
                    contactSection.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




