import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Softools nos ha prestado un servicio sobresaliente, demostrando gran capacidad para entender nuestras ideas y aplicarlas de forma ágil y creativa en cada proyecto. Su disposición y eficiencia han sido claves para el éxito de nuestras iniciativas."
            author="Alejandra Rendon - Credidya"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Para nuestra empresa, Softools nos ha brindado una solución completa, ágil y en constante mejora. Tras más de 15 años de uso, hemos disfrutado de un excelente servicio, siempre cumpliendo nuestras necesidades."
            author="Ximena Zapata - Cosmos"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="El 10 de febrero de 2025 inició la operación de ACREDITAR, otorgando microcréditos a comercios, inicialmente en CASAENSUEÑO.
            Se implementó la plataforma de software de SOFTOOLS, que brindó un proceso eficiente, rápido y seguro."
            author="Juan Pablo Choconta - Acreditar"
          />
        </LogoWrapper>
        {/* <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it."
            author="Ralph Waldo Emerson"
          />
        </LogoWrapper> */}
        {/* <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it."
            author="Ralph Waldo Emerson"
          />
        </LogoWrapper> */}
        {/* <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it."
            author="Ralph Waldo Emerson"
          />
        </LogoWrapper> */}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
