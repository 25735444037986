import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43.336}
    height={32.531}
    viewBox="0 0 43.336 32.531"
    {...props}
  >
    <g data-name="Group 74">
      {/* Fondo o forma principal en un color diferente a los anteriores (#05b08d) */}
      <path
        data-name="Path 127"
        d="M38.562 24.112H4.778a3.428 3.428 0 01-3.414-3.406V4.776a3.424 3.424 0 013.409-3.412h33.784a3.425 3.425 0 013.41 3.41V20.7a3.425 3.425 0 01-3.407 3.412z"
        fill="#05b08d"
      />
      {/* Detalles y contorno en #0b093b */}
      {/* Incluye tres cuadrados para evocar la idea de microservicios independientes */}
      <path
        data-name="Path 128"
        d="M38.559 0H4.778A4.785 4.785 0 000 4.776v15.922a4.786 4.786 0 004.778 4.778h13.507v4.322h-3.926a.684.684 0 00-.681.683v1.366a.684.684 0 00.681.683h14.619a.685.685 0 00.682-.683v-1.366a.685.685 0 00-.682-.683h-3.926v-4.322H38.56a4.786 4.786 0 004.776-4.778V4.776A4.785 4.785 0 0038.559 0zM22.321 29.8h-1.308v-4.322h1.308zm18.283-9.1a2.068 2.068 0 01-2.045 2.048H4.778a2.07 2.07 0 01-2.05-2.048V4.778a2.069 2.069 0 012.048-2.047h33.782a2.069 2.069 0 012.045 2.047zM12 10h4v4h-4zM20 10h4v4h-4zM28 10h4v4h-4z"
        fill="#0b093b"
      />
    </g>
  </svg>  
  );
}

export default SvgComponent;
