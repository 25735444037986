import React, { useState } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import { Resend } from 'resend';

export default function Contact() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault(); // Evita que el form haga un refresh de la página
    // Extrae los valores directamente de los inputs
    const fname = event.target.fname.value;
    const email = event.target.email.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const formData={
      subject: subject,
      html: `
      <h1>Nuevo mensaje de contacto</h1>
      <p><strong>Nombre:</strong> ${fname}</p>
      <p><strong>Email:</strong> ${email}</p>
      <p><strong>Mensaje:</strong></p>
      <p>${message}</p>
    `,
    };

    try {
      // Envía el correo usando Resend
      const response = await fetch('https://www.softools.com.co/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        setSuccessMessage("Correo enviado con éxito");
        setErrorMessage("");
         // Limpia los campos del formulario
         event.target.reset();
         setTimeout(() => {
          setSuccessMessage("");
        }, 9000);
      } else {
        setSuccessMessage("");
        setErrorMessage(`Error al enviar correo: ${result.error}`);
      }

    } catch (error) {
      console.error('Error al enviar el correo:', error);
      // Aquí podrías manejar errores, mostrar un mensaje de error, etc.
    }
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Ponte en contacto</h1>
            <p className="font13">
              Comunicate con nosotros.
              {/* <br />
              labore et dolore magna aliquyam erat, sed diam voluptua. */}
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form onSubmit={handleSubmit} id="formcontact">
                <label className="font13">Primer Nombre:</label>
                <input type="text" id="fname" name="fname" required className="font20 extraBold" />
                <label className="font13">Correo:</label>
                <input type="text" id="email" name="email" required className="font20 extraBold" />
                <label className="font13">Asunto:</label>
                <input type="text" id="subject" name="subject" required className="font20 extraBold" />
                <textarea rows="4" cols="50" type="text" id="message" name="message" required className="font20 extraBold" />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput form="formcontact" type="submit" value="Enviar" className="pointer animate radius8" style={{ maxWidth: "220px" }} />
              </SumbitWrapper>
              {successMessage && <div className="font20 extraBold" style={{ color: "green", marginBottom: "10px" }}>{successMessage}</div>}
      {errorMessage && <div className="font20 extraBold" style={{ color: "red", marginBottom: "10px" }}>{errorMessage}</div>}

            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
 /*border: 1px solid #7620ff;*/
  /*background-color: #7620ff;*/
  border:1px solid #0C8BCD;
  background-color: #0C8BCD;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    /* border: 1px solid #7620ff;*/
     border: 1px solid #0C8BCD;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









