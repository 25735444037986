import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  /* border: 1px solid ${(props) => (props.border ? "#707070" : "#7620ff")};*/
  /* background-color: ${(props) => (props.border ? "transparent" : "#7620ff")};*/
    border: 1px solid ${(props) => (props.border ? "#707070" : "#0C8BCD")};
  background-color: ${(props) => (props.border ? "transparent" : "#0C8BCD")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#580cd2")};
    /* border: 1px solid #7620ff;*/
    border:1px solid #0C8BCD;
    /*color: ${(props) => (props.border ? "#7620ff" : "#fff")};*/
    color:${(props) => (props.border ? "#0C8BCD" : "#fff")};
  }
`;

